import { default as _91_46_46_46catchAll_93XUR2FhCncJMeta } from "/codebuild/output/src3585622221/src/storefront/pages/[...catchAll].vue?macro=true";
import { default as about_45us9TU4iRnUupMeta } from "/codebuild/output/src3585622221/src/storefront/pages/about-us.vue?macro=true";
import { default as barion_45startnZxRwQvygZMeta } from "/codebuild/output/src3585622221/src/storefront/pages/barion-start.vue?macro=true";
import { default as bestsellers_45of_45the_45monthiBPqUQ0hnCMeta } from "/codebuild/output/src3585622221/src/storefront/pages/bestsellers-of-the-month.vue?macro=true";
import { default as book_45club2QuTV7d2YsMeta } from "/codebuild/output/src3585622221/src/storefront/pages/book-club.vue?macro=true";
import { default as _91slug_93VyUsjfrpwdMeta } from "/codebuild/output/src3585622221/src/storefront/pages/book/[slug].vue?macro=true";
import { default as _91slug_934bkbjqcwAaMeta } from "/codebuild/output/src3585622221/src/storefront/pages/books-by-author/[slug].vue?macro=true";
import { default as indexcg4501YNEdMeta } from "/codebuild/output/src3585622221/src/storefront/pages/books/[category]/[subcategory]/index.vue?macro=true";
import { default as indexDuSezCnRaHMeta } from "/codebuild/output/src3585622221/src/storefront/pages/books/[category]/index.vue?macro=true";
import { default as indexm20ngOuRXRMeta } from "/codebuild/output/src3585622221/src/storefront/pages/books/index.vue?macro=true";
import { default as indexSu6xJbC7bVMeta } from "/codebuild/output/src3585622221/src/storefront/pages/checkout/index.vue?macro=true";
import { default as thankyouY0tbRZYtc6Meta } from "/codebuild/output/src3585622221/src/storefront/pages/checkout/thankyou.vue?macro=true";
import { default as contact_45usunADiu4gp8Meta } from "/codebuild/output/src3585622221/src/storefront/pages/contact-us.vue?macro=true";
import { default as cookie0lurIjOUZMMeta } from "/codebuild/output/src3585622221/src/storefront/pages/cookie.vue?macro=true";
import { default as indexP5E4Zah1lWMeta } from "/codebuild/output/src3585622221/src/storefront/pages/index.vue?macro=true";
import { default as login1LPsFG6GenMeta } from "/codebuild/output/src3585622221/src/storefront/pages/login.vue?macro=true";
import { default as _91slug_93IjCdtDVO2HMeta } from "/codebuild/output/src3585622221/src/storefront/pages/menu-navigation/[slug].vue?macro=true";
import { default as my_45cart61PH2SVTwfMeta } from "/codebuild/output/src3585622221/src/storefront/pages/my-cart.vue?macro=true";
import { default as _91slug_93f9jWd3WXNpMeta } from "/codebuild/output/src3585622221/src/storefront/pages/my-orders/[slug].vue?macro=true";
import { default as indexdJd9j8LQHWMeta } from "/codebuild/output/src3585622221/src/storefront/pages/my-orders/index.vue?macro=true";
import { default as newly_45published9jxTjBix8iMeta } from "/codebuild/output/src3585622221/src/storefront/pages/newly-published.vue?macro=true";
import { default as newspapersxn9dHr3ixEMeta } from "/codebuild/output/src3585622221/src/storefront/pages/newspapers.vue?macro=true";
import { default as on_45saleC2Mf5K70AEMeta } from "/codebuild/output/src3585622221/src/storefront/pages/on-sale.vue?macro=true";
import { default as _91slug_939fjsu8aPoZMeta } from "/codebuild/output/src3585622221/src/storefront/pages/other-language/[slug].vue?macro=true";
import { default as indexCOIC7Bvc3qMeta } from "/codebuild/output/src3585622221/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue?macro=true";
import { default as indexgLdJUkqagzMeta } from "/codebuild/output/src3585622221/src/storefront/pages/other-languages/[category]/index.vue?macro=true";
import { default as index2UW44NbfjsMeta } from "/codebuild/output/src3585622221/src/storefront/pages/other-languages/index.vue?macro=true";
import { default as payment_45resultuiWSgjeP03Meta } from "/codebuild/output/src3585622221/src/storefront/pages/payment-result.vue?macro=true";
import { default as paypal_45startPCZ9uiLuONMeta } from "/codebuild/output/src3585622221/src/storefront/pages/paypal-start.vue?macro=true";
import { default as privacy_45policybmJFNYhfVpMeta } from "/codebuild/output/src3585622221/src/storefront/pages/privacy-policy.vue?macro=true";
import { default as _91_91subcategory_93_93CgC3VjPitwMeta } from "/codebuild/output/src3585622221/src/storefront/pages/products/[[category]]/[[subcategory]].vue?macro=true";
import { default as purchase_45and_45shipping_45conditionssJ9i68FMp4Meta } from "/codebuild/output/src3585622221/src/storefront/pages/purchase-and-shipping-conditions.vue?macro=true";
import { default as search2728NGxXCLMeta } from "/codebuild/output/src3585622221/src/storefront/pages/search.vue?macro=true";
import { default as staff_45recommendationsP4CvAlnybTMeta } from "/codebuild/output/src3585622221/src/storefront/pages/staff-recommendations.vue?macro=true";
import { default as _91slug_93hGC2ladJZoMeta } from "/codebuild/output/src3585622221/src/storefront/pages/stationary-and-gift/[slug].vue?macro=true";
import { default as indexErNQf9F7lKMeta } from "/codebuild/output/src3585622221/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue?macro=true";
import { default as indexZ7TjmtzbTsMeta } from "/codebuild/output/src3585622221/src/storefront/pages/stationary-and-gifts/[category]/index.vue?macro=true";
import { default as indexjiqjsKIkomMeta } from "/codebuild/output/src3585622221/src/storefront/pages/stationary-and-gifts/index.vue?macro=true";
import { default as terms_45and_45conditions4S9hgMp9N3Meta } from "/codebuild/output/src3585622221/src/storefront/pages/terms-and-conditions.vue?macro=true";
import { default as upcoming_45releasesiDn7KiMYKyMeta } from "/codebuild/output/src3585622221/src/storefront/pages/upcoming-releases.vue?macro=true";
export default [
  {
    name: "catchAll___hu",
    path: "/hu/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93XUR2FhCncJMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/[...catchAll].vue").then(m => m.default || m)
  },
  {
    name: "catchAll___en",
    path: "/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93XUR2FhCncJMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/[...catchAll].vue").then(m => m.default || m)
  },
  {
    name: "about-us___hu",
    path: "/hu/rolunk",
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "about-us___en",
    path: "/about-us",
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "barion-start___hu",
    path: "/hu/barion-fizetes",
    meta: barion_45startnZxRwQvygZMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/barion-start.vue").then(m => m.default || m)
  },
  {
    name: "barion-start___en",
    path: "/barion-start",
    meta: barion_45startnZxRwQvygZMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/barion-start.vue").then(m => m.default || m)
  },
  {
    name: "bestsellers-of-the-month___hu",
    path: "/hu/a-honap-sikerkonyvei",
    meta: bestsellers_45of_45the_45monthiBPqUQ0hnCMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/bestsellers-of-the-month.vue").then(m => m.default || m)
  },
  {
    name: "bestsellers-of-the-month___en",
    path: "/bestsellers-of-the-month",
    meta: bestsellers_45of_45the_45monthiBPqUQ0hnCMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/bestsellers-of-the-month.vue").then(m => m.default || m)
  },
  {
    name: "book-club___hu",
    path: "/hu/konyvklub",
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/book-club.vue").then(m => m.default || m)
  },
  {
    name: "book-club___en",
    path: "/book-club",
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/book-club.vue").then(m => m.default || m)
  },
  {
    name: "book-slug___hu",
    path: "/hu/konyv/:slug()",
    meta: _91slug_93VyUsjfrpwdMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/book/[slug].vue").then(m => m.default || m)
  },
  {
    name: "book-slug___en",
    path: "/book/:slug()",
    meta: _91slug_93VyUsjfrpwdMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/book/[slug].vue").then(m => m.default || m)
  },
  {
    name: "books-by-author-slug___hu",
    path: "/hu/szerzo-muvei/:slug()",
    meta: _91slug_934bkbjqcwAaMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/books-by-author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "books-by-author-slug___en",
    path: "/books-by-author/:slug()",
    meta: _91slug_934bkbjqcwAaMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/books-by-author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "books-category-subcategory___hu",
    path: "/hu/konyvek/:category()/:subcategory()",
    meta: indexcg4501YNEdMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/books/[category]/[subcategory]/index.vue").then(m => m.default || m)
  },
  {
    name: "books-category-subcategory___en",
    path: "/books/:category()/:subcategory()",
    meta: indexcg4501YNEdMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/books/[category]/[subcategory]/index.vue").then(m => m.default || m)
  },
  {
    name: "books-category___hu",
    path: "/hu/konyvek/:category()",
    meta: indexDuSezCnRaHMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/books/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "books-category___en",
    path: "/books/:category()",
    meta: indexDuSezCnRaHMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/books/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "books___hu",
    path: "/hu/konyvek",
    meta: indexm20ngOuRXRMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/books/index.vue").then(m => m.default || m)
  },
  {
    name: "books___en",
    path: "/books",
    meta: indexm20ngOuRXRMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/books/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___hu",
    path: "/hu/megrendeles",
    meta: indexSu6xJbC7bVMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___en",
    path: "/checkout",
    meta: indexSu6xJbC7bVMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-thankyou___hu",
    path: "/hu/megrendeles-koszonjuk",
    meta: thankyouY0tbRZYtc6Meta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/checkout/thankyou.vue").then(m => m.default || m)
  },
  {
    name: "checkout-thankyou___en",
    path: "/checkout-thankyou",
    meta: thankyouY0tbRZYtc6Meta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/checkout/thankyou.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___hu",
    path: "/hu/kapcsolat",
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___en",
    path: "/contact-us",
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "cookie___hu",
    path: "/hu/suti",
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/cookie.vue").then(m => m.default || m)
  },
  {
    name: "cookie___en",
    path: "/cookie",
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/cookie.vue").then(m => m.default || m)
  },
  {
    name: "index___hu",
    path: "/hu",
    meta: indexP5E4Zah1lWMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: indexP5E4Zah1lWMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login___hu",
    path: "/hu/bejelentkezes",
    meta: login1LPsFG6GenMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/login",
    meta: login1LPsFG6GenMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "menu-navigation-slug___hu",
    path: "/hu/menu-navigacio/:slug()",
    meta: _91slug_93IjCdtDVO2HMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/menu-navigation/[slug].vue").then(m => m.default || m)
  },
  {
    name: "menu-navigation-slug___en",
    path: "/menu-navigation/:slug()",
    meta: _91slug_93IjCdtDVO2HMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/menu-navigation/[slug].vue").then(m => m.default || m)
  },
  {
    name: "my-cart___hu",
    path: "/hu/kosaram",
    meta: my_45cart61PH2SVTwfMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/my-cart.vue").then(m => m.default || m)
  },
  {
    name: "my-cart___en",
    path: "/my-cart",
    meta: my_45cart61PH2SVTwfMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/my-cart.vue").then(m => m.default || m)
  },
  {
    name: "my-orders-slug___hu",
    path: "/hu/rendeleseim/:slug()",
    meta: _91slug_93f9jWd3WXNpMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/my-orders/[slug].vue").then(m => m.default || m)
  },
  {
    name: "my-orders-slug___en",
    path: "/my-orders/:slug()",
    meta: _91slug_93f9jWd3WXNpMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/my-orders/[slug].vue").then(m => m.default || m)
  },
  {
    name: "my-orders___hu",
    path: "/hu/rendeleseim",
    meta: indexdJd9j8LQHWMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/my-orders/index.vue").then(m => m.default || m)
  },
  {
    name: "my-orders___en",
    path: "/my-orders",
    meta: indexdJd9j8LQHWMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/my-orders/index.vue").then(m => m.default || m)
  },
  {
    name: "newly-published___hu",
    path: "/hu/ujdonsagok",
    meta: newly_45published9jxTjBix8iMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/newly-published.vue").then(m => m.default || m)
  },
  {
    name: "newly-published___en",
    path: "/newly-published",
    meta: newly_45published9jxTjBix8iMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/newly-published.vue").then(m => m.default || m)
  },
  {
    name: "newspapers___hu",
    path: "/hu/ujsagok-es-magazinok",
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/newspapers.vue").then(m => m.default || m)
  },
  {
    name: "newspapers___en",
    path: "/newspapers-and-magazines",
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/newspapers.vue").then(m => m.default || m)
  },
  {
    name: "on-sale___hu",
    path: "/hu/akciok",
    meta: on_45saleC2Mf5K70AEMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/on-sale.vue").then(m => m.default || m)
  },
  {
    name: "on-sale___en",
    path: "/on-sale",
    meta: on_45saleC2Mf5K70AEMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/on-sale.vue").then(m => m.default || m)
  },
  {
    name: "other-language-slug___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyv/:slug()",
    meta: _91slug_939fjsu8aPoZMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/other-language/[slug].vue").then(m => m.default || m)
  },
  {
    name: "other-language-slug___en",
    path: "/other-language/:slug()",
    meta: _91slug_939fjsu8aPoZMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/other-language/[slug].vue").then(m => m.default || m)
  },
  {
    name: "other-languages-category-subcategory___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek/:categorysubcategory?",
    meta: indexCOIC7Bvc3qMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue").then(m => m.default || m)
  },
  {
    name: "other-languages-category-subcategory___en",
    path: "/other-languages/:category()/:subcategory()",
    meta: indexCOIC7Bvc3qMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue").then(m => m.default || m)
  },
  {
    name: "other-languages-category___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek/:category()",
    meta: indexgLdJUkqagzMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/other-languages/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "other-languages-category___en",
    path: "/other-languages/:category()",
    meta: indexgLdJUkqagzMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/other-languages/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "other-languages___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek",
    meta: index2UW44NbfjsMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/other-languages/index.vue").then(m => m.default || m)
  },
  {
    name: "other-languages___en",
    path: "/other-languages",
    meta: index2UW44NbfjsMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/other-languages/index.vue").then(m => m.default || m)
  },
  {
    name: "payment-result___hu",
    path: "/hu/barion-eredmeny",
    meta: payment_45resultuiWSgjeP03Meta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/payment-result.vue").then(m => m.default || m)
  },
  {
    name: "payment-result___en",
    path: "/barion-result",
    meta: payment_45resultuiWSgjeP03Meta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/payment-result.vue").then(m => m.default || m)
  },
  {
    name: "paypal-start___hu",
    path: "/hu/paypal-fizetes",
    meta: paypal_45startPCZ9uiLuONMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/paypal-start.vue").then(m => m.default || m)
  },
  {
    name: "paypal-start___en",
    path: "/paypal-start",
    meta: paypal_45startPCZ9uiLuONMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/paypal-start.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___hu",
    path: "/hu/adatkezelesi-tajekoztato",
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "products-category-subcategory___hu",
    path: "/hu/product/:category?/:subcategory?",
    meta: _91_91subcategory_93_93CgC3VjPitwMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/products/[[category]]/[[subcategory]].vue").then(m => m.default || m)
  },
  {
    name: "products-category-subcategory___en",
    path: "/products/:category?/:subcategory?",
    meta: _91_91subcategory_93_93CgC3VjPitwMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/products/[[category]]/[[subcategory]].vue").then(m => m.default || m)
  },
  {
    name: "purchase-and-shipping-conditions___hu",
    path: "/hu/vasarlasi-es-szallitasi-feltetelek",
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/purchase-and-shipping-conditions.vue").then(m => m.default || m)
  },
  {
    name: "purchase-and-shipping-conditions___en",
    path: "/purchase-and-shipping-conditions",
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/purchase-and-shipping-conditions.vue").then(m => m.default || m)
  },
  {
    name: "search___hu",
    path: "/hu/kereses",
    meta: search2728NGxXCLMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/search",
    meta: search2728NGxXCLMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "staff-recommendations___hu",
    path: "/hu/mi-ajanljuk",
    meta: staff_45recommendationsP4CvAlnybTMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/staff-recommendations.vue").then(m => m.default || m)
  },
  {
    name: "staff-recommendations___en",
    path: "/staff-recommendations",
    meta: staff_45recommendationsP4CvAlnybTMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/staff-recommendations.vue").then(m => m.default || m)
  },
  {
    name: "stationary-and-gift-slug___hu",
    path: "/hu/notesz-es-ajandektargy/:slug()",
    meta: _91slug_93hGC2ladJZoMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/stationary-and-gift/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stationary-and-gift-slug___en",
    path: "/stationary-and-gift/:slug()",
    meta: _91slug_93hGC2ladJZoMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/stationary-and-gift/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stationary-and-gifts-category-subcategory___hu",
    path: "/hu/noteszek-es-ajandektargyak/:categorysubcategory?",
    meta: indexErNQf9F7lKMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue").then(m => m.default || m)
  },
  {
    name: "stationary-and-gifts-category-subcategory___en",
    path: "/stationary-and-gifts/:category()/:subcategory()",
    meta: indexErNQf9F7lKMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue").then(m => m.default || m)
  },
  {
    name: "stationary-and-gifts-category___hu",
    path: "/hu/noteszek-es-ajandektargyak/:category()",
    meta: indexZ7TjmtzbTsMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/stationary-and-gifts/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "stationary-and-gifts-category___en",
    path: "/stationary-and-gifts/:category()",
    meta: indexZ7TjmtzbTsMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/stationary-and-gifts/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "stationary-and-gifts___hu",
    path: "/hu/noteszek-es-ajandektargyak",
    meta: indexjiqjsKIkomMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/stationary-and-gifts/index.vue").then(m => m.default || m)
  },
  {
    name: "stationary-and-gifts___en",
    path: "/stationary-and-gifts",
    meta: indexjiqjsKIkomMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/stationary-and-gifts/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___hu",
    path: "/hu/altalanos-szerzodesi-feltetelek",
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en",
    path: "/terms-and-conditions",
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "upcoming-releases___hu",
    path: "/hu/varhato-megjelenesek",
    meta: upcoming_45releasesiDn7KiMYKyMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/upcoming-releases.vue").then(m => m.default || m)
  },
  {
    name: "upcoming-releases___en",
    path: "/upcoming-releases",
    meta: upcoming_45releasesiDn7KiMYKyMeta || {},
    component: () => import("/codebuild/output/src3585622221/src/storefront/pages/upcoming-releases.vue").then(m => m.default || m)
  }
]